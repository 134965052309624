<template>
    <div class="page">
      <template v-if="user.user_type === 'Candidate'">
        <CandidateReports :userId="user.id" />
      </template>
      <template v-else>
        Отчеты есть только у кандидатов
      </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CandidateReports from '@/components/candidates/profile/CandidateReports';

export default {
  name: 'CandidateReportsPage',
  components: {
    CandidateReports,
  },
  data() {
    return {
    };
  },
  provide: {
    isProfile: true,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
